module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Michele Corley Clinical Skin Care","description":"Professional skin care products available through licensed estheticians. Clean, safe, efficacious formulas.","short_name":"mcc","start_url":"/","background_color":"#dcdcdc","theme_color":"#dcdcdc","display":"standalone","icon":"src/images/maskable_icon.png","icon_options":{"purpose":"any maskable"},"crossOrigin":"use-credentials","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"e49f434dfc951f566455d926e519a459"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
